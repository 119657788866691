import React from 'react';
import PropTypes from 'prop-types';
import ProductCardSimple from './ProductCardSimple';

import { getLowestVariantPrice, findMetafieldValue } from '../../lib/product';
import findImage from '../../lib/findImage';

const RelatedCardComponent = (props) => (
  <ProductCardSimple
    {...props}
    short_description={
      props.shortDescription?.value ||
      findMetafieldValue(props.metafields || [], 'short_description') ||
      props.description
    }
    imageUrl={findImage(props.media, '_grid')}
    price={getLowestVariantPrice(props.variants)}
  />
);
RelatedCardComponent.propTypes = {
  images: PropTypes.array.isRequired,
  description: PropTypes.string,
  variants: PropTypes.array,
  shortDescription: PropTypes.string,
  metafields: PropTypes.array,
  media: PropTypes.array,
};

export default RelatedCardComponent;
